$color-blue:                #006ac5;
$color-text:                #849098;
$color-light-bg:            #ecf0f1;
$color-medium-bg:           #c0cbce;
$color-black:               #000;
$color-white:               #fff;
$color-yellow:              #fee100;
$color-bg:                  $color-white;
$color-orange:              #e67f22;
$color-green:               #9cd245;
$color-red:                 #c20b19;
$color-dark-red:            mix(black, $color-red, 15);
$color-purple:              #a87eea;
$color-lighter-text:        mix(white, $color-text, 25);
$color-darker-text:         mix(black, $color-text, 25);

$color-projectmenu-bg:      $color-blue;
$color-a-hover:             $color-blue;
$color-borders:             $color-text;
$color-usermenu-bg:         $color-borders;

$color-mytasks:             #00baff;
$color-deffered:            #f2c510;
$color-all-tasks:           $color-orange;
$color-done:                $color-green;
$color-solved:              $color-purple;

$color-mytasks-shadow:      #0978D2;
$color-deffered-shadow:     #f4ab10;
$color-all-tasks-shadow:    #d15400;
$color-done-shadow:         #74ae4a;
$color-solved-shadow:       #8154c8;

$color-priority-urgent:     $color-red;
$color-priority-high:       $color-orange;
$color-priority-low:        $color-yellow;

$color-tablerow-odd:        $color-bg;
$color-tablerow-even:       #f7f8f7;
$color-tablegroup-odd:      #95a8b1;
$color-tablegroup-even:     #71848a;
// nove?
//$color-tablerow-even:       $color-light-bg;

$color-donebar-bg:          #cbd1d6;
$color-donebar-done:        #859097;

$color-favourite-bg:        $color-yellow;
$color-favourite-bg-shadow: #ffc800;

$color-full-star:           #f6d111;

$tiles-border-radius:       0.8em;
$color-row-hover:           #fbeeb7;

$color-checkbox-toggle:     #ccc;
$color-issue-bg:            $color-light-bg;
$color-search-highlight:    #fcfd8d;

$color-input-border:        #ccc;
$color-input-border-focus:  $color-text; //#00b4eb;
$color-input-border-hover:  $color-text;
$color-input-placeholder:   #aaa;

$color-info-text:           #31708f;
$color-info-background:     #d9edf7;
$color-info-border:         #bce8f1;
$color-warning-text:        #8a6d3b;
$color-warning-background:  #fcf8e3;
$color-warning-border:      #faebcc;
$color-success-text:        #3c763d;
$color-success-background:  #dff0d8;
$color-success-border:      #d6e9c6;
$color-error-text:          #a94442;
$color-error-background:    #f2dede;
$color-error-border:        #ebccd1;

$color-primary:             #2684ff;

@import "tailwindcss/base.css";
@import "tailwindcss/components.css";
@import "tailwindcss/utilities.css";

@import "stylesheets/colors";

@layer base {
  html,
  body {
    @apply font-sans text-base;
  }

  h1 {
    @apply text-3xl font-normal text-blue-base;
  }

  h2 {
    @apply text-[1.2em] font-normal text-blue-base;
  }

  h3 {
    @apply text-[1.1em] font-normal text-blue-base;
  }

  h4 {
    @apply text-[1em] font-normal text-blue-base;
  }

  h5 {
    @apply text-base font-normal;
  }
}

.btn,
button.btn[type="button"],
button.btn[type="reset"],
button.btn[type="submit"],
.btn {
  @apply appearance-none inline-flex items-center bg-blue-base hover:bg-blue-dark min-w-fit h-[32px] px-sm text-white text-base leading-loose shadow-md shadow-black/20 transition-colors duration-200 ease-in-out;

  &.selected {
    @apply bg-zinc-500 cursor-default;
  }

  &.fill {
    @apply w-full flex items-center justify-center;
  }

  i[class*="icon-"] {
    @apply relative inline-block w-[20px] h-[20px];

    &.icon-plus {
      @apply top-[2px] mr-xs;
    }

    &.icon-p-download {
      @apply top-[2px];
    }
  }
}

textarea {
  @apply p-sm;
}

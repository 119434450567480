@import "tailwind";
@import "shared/pnotify";
@import "shared/pnotify";

.office365 {
  float: right;

  &-link {
    margin-top: 1rem;
    float: right;
  }
}

.googleLog,
.officeLog {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 1em;
  border: 1px #8c8c8c solid;
  border-radius: 1px;
  padding: 8px;
  color: $color-text;

  &:hover {
    color: $color-text;
    text-decoration: underline;
  }

  span {
    margin-left: 18px;
    a:hover {
      color: $color-text;
    }
  }
}

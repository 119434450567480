@import "stylesheets/colors"; // import colors from old styles

.row {
  &--form {
    margin-bottom: 1rem;
  }

  .row {
    &--form {
      margin-bottom: 1rem;

      label {
        line-height: 2.2em;
        font-size: 1rem;
      }
    }
  }
}

form {
  margin-bottom: 0;
}

label {
  line-height: 32px;
  font-weight: 400;
  cursor: default;

  &[for] {
    cursor: pointer;
  }
}

textarea {
  width: 100%;
  margin-bottom: 1rem;
  border: 1px solid $color-input-border;
  transition: border-color 0.2s ease-in-out;
  outline: none;

  &:hover {
    border-color: $color-text;
  }
}

.checkbox {
  position: relative;
  line-height: initial;

  &__label {
    &--left {
      margin-right: 0.4em;
    }

    &--right {
      margin-left: 0.4em;
    }
  }

  &--disabled {
    cursor: default;

    &:hover {
      color: $color-red;
    }
  }

  i {
    font-weight: normal;
    font-size: 1.4em;
    color: $color-text;
    vertical-align: text-bottom;

    &.icon-p-checkbox-checked.is-checked {
      color: $color-blue;
    }
  }
}

.inline-input input,
input.dateinput {
  cursor: text;
  color: $color-text;
  width: 100%;
  display: block;
  background-color: transparent !important;
  border: 0;
  box-shadow: none;
  height: initial;
  margin: 0;
  padding: 0 0.5em;
  line-height: 1.5em;
  border-bottom: 1px $color-input-border solid;
  -webkit-transition: border-color 0.2s ease-in;
  -moz-transition: border-color 0.2s ease-in;
  -ms-transition: border-color 0.2s ease-in;
  -o-transition: border-color 0.2s ease-in;
  transition: border-color 0.2s ease-in;

  &:hover {
    border-bottom: 1px $color-input-border-hover solid;
  }

  &:focus {
    background-color: transparent !important;
    box-shadow: none;
    border-bottom: 1px solid $color-input-border-focus;
  }
}

.TextField {
  $tf: &;

  display: inline-block;
  width: 100%;
  font-size: 1rem;

  &__control {
    position: relative;
    display: flex;
    align-items: center;
    height: 32px;
    background-color: $color-white;
    border: 1px solid $color-input-border;
    transition: border-color 0.2s ease-in-out;

    &:hover {
      border: 1px solid $color-input-border-hover;
    }

    &:focus-within {
      border: 1px solid $color-input-border-focus;
    }

    input {
      display: inline-block;
      margin: 0 6px;
      padding: 0;
      line-height: 100%;
      color: $color-black;
      border: none;
      overflow: hidden;
      text-overflow: ellipsis;

      &:focus {
        outline: none;
      }
    }

    #{$tf}__clear {
      height: 14px;
      margin-right: 8px;
      cursor: pointer;

      svg {
        fill: $color-input-border;
        transition: fill 0.2s ease-in-out;
      }

      &:hover svg {
        fill: $color-input-border-hover;
      }
    }
  }

  &__leftIcon {
    margin-left: 0.3rem;
    line-height: 100%;
    color: $color-input-placeholder;
  }

  &--inline {
    #{$tf}__control {
      background: transparent;
      border: 1px transparent;
      border-bottom: 1px solid $color-input-border;

      &:hover {
        border: 1px transparent;
        border-bottom: 1px solid $color-input-border-hover;
      }

      &:focus-within {
        border: 1px transparent;
        border-bottom: 1px solid $color-input-border-focus;
      }

      input {
        background: transparent;
        height: 32px;
      }

      #{$tf}__clear {
        margin-right: 4px;
      }
    }
  }

  &--has-error input {
    border-color: $color-red;
  }

  &--error-text {
    display: inline-block;
    padding: 4px 0 0 6px;
    color: $color-red;
  }
}

@media print {
  .TextField {
    &__clear {
      display: none !important;
    }
  }
}

.DayPicker {
  outline: none;

  &-Day {
    outline: none;
  }
}

.TimeInput {
  position: relative;
  display: inline-block;

  &__input {
    display: inline-block;
    width: 7em;
  }

  &__button {
    width: 14px;
    height: 14px;
    line-height: 10px;
    font-size: 10px;
    border: none;
    margin: 0 auto;
    background: $color-light-bg;
    text-align: center;
    position: absolute;

    &:hover {
      background-color: mix(black, $color-light-bg, 10);
    }

    &.icon-chevron-up {
      border-top-left-radius: 2em;
      border-top-right-radius: 2em;
      top: 2px;
      right: 2px;
    }

    &.icon-chevron-down {
      border-bottom-left-radius: 2em;
      border-bottom-right-radius: 2em;
      top: 17px;
      right: 2px;
    }
  }

  &__error {
    position: absolute;
    display: inline-block;
    top: 0.25em;
    right: -4em;
    color: $color-red;
  }
}

.react-switch-bg {
  box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.1);
}

@import '../../javascripts/vendor/pnotify/styles';
@import '../../../app/assets/stylesheets/colors'; // import colors from old styles

.ui-pnotify {
  .ui-pnotify-container {
    h4 {
      font-size: 1.3em;
    }
  }
}

.ui-pnotify-closer {
  outline: none;
}

.brighttheme-icon-closer {
  font-size: 1.5em;
  color: $color-error-text;
}

.brighttheme .ui-pnotify-title {
  margin-bottom: .5em;
}

.ui-pnotify.info {
  .ui-pnotify-container {
    background-color:  $color-info-background;

    .ui-pnotify-text, .ui-pnotify-title {
      color: $color-info-text;
    }
  }
}

.ui-pnotify.success {
  .ui-pnotify-container {
    background-color:  $color-success-background;

    .ui-pnotify-text, .ui-pnotify-title {
      color: $color-success-text;
    }
  }
}

.ui-pnotify.warning {
  .ui-pnotify-container {
    background-color:  $color-warning-background;

    .ui-pnotify-text, .ui-pnotify-title {
      color: $color-warning-text;
    }
  }
}

.ui-pnotify.error {
  .ui-pnotify-container {
    background-color:  $color-error-background;

    .ui-pnotify-text, .ui-pnotify-title {
      color: $color-error-text;
    }
  }
}

.ui-pnotify .ui-pnotify-shadow {
  box-shadow: 2px 2px 3px rgba(0, 0, 0, .2);
  -webkit-box-shadow: 2px 2px 3px rgba(0, 0, 0, .2);
}
